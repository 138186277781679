import { Link, navigate } from "gatsby";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Header from "../../components/Header";
import { UserContext } from "../../components/Layout";
import LoadingSpinner from "../../components/LoadingSpinner";
import Notification from "../../components/Notification";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { API_ROOT } from "../../constants";
import AccountErrorMessage from "../../components/AccountErrorMessage";
import Snackbar from "@mui/material/Snackbar";
import getSelectedLang, {
  getSelectedFlashLang,
  setSelectedLang,
  setSelectedFlashLang,
} from "../../util/getSelectedLang";
import getSelectedScreenEffect, {
  setSelectedScreenEffect,
} from "../../util/getSelectedScreenEffect";
// import getSelectedLockedVideo, {
//   setSelectedLockedVideo,
// } from "../../util/getSelectedLockedVideo";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h1 {
    text-align: center;
  }

  .notification {
    margin: 0 0 20px 0;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  li {
    margin-bottom: 8px;
  }
`;

const Form = styled.form`
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 300px;
  font-size: 22px;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 60px;
  position: relative;
  background: #fff;

  * {
    font-size: inherit;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      color: #222;
    }

    input {
      border: 1px solid rgb(0 0 0 / 90%);
      box-sizing: border-box;
      margin: 3px;
      padding: 3px;
      font-size: 16px;
      line-height: 26px;
      border-radius: 3px;
    }
  }

  button {
    box-sizing: border-box;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
    align-self: center;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const initialSnackbarState = {
  isOpen: false,
  message: "",
};

function Account() {
  const user = useContext(UserContext);
  const [snackbar, setSnackbar] = useState(initialSnackbarState);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be 6 characters or more")
        .required("Password is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    onSubmit: async (values) => {
      const { data } = await axios.post(API_ROOT + "users/create-account", {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim().toLowerCase(),
        uuid: user.uuid,
      });

      setSnackbar({
        isOpen: true,
        message: data.message,
      });
    },
  });

  const handleClose = () => {
    setSnackbar(initialSnackbarState);
  };

  if (user.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Container>
        {user.status !== "anonymous" ? (
          <AccountContent user={user} />
        ) : (
          <>
            <Header>Create Account</Header>
            <Notification>
              You are currently registered <strong>anonymously</strong>. <br />
              <br />
              Creating an account allows you to use the site unfettered and
              allows you to log in on other devices.
              <br />
              <br />
              Confirmation email may end up in spam folder.
            </Notification>
            <Form onSubmit={formik.handleSubmit}>
              <AccountErrorMessage
                touched={formik.touched}
                formikErrors={formik.errors}
              />
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>
              <button type="submit">Submit</button>
              <Link to="/account/login">Or Log in</Link>
            </Form>{" "}
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbar.isOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              message={snackbar.message}
            />
          </>
        )}
      </Container>
    </>
  );
}

function AccountContent(props) {
  const { user } = props;
  const [selectedLang, _setSelectedLang] = useState(getSelectedLang());
  const [selectedFlashLang, _setSelectedFlashLang] = useState(
    getSelectedFlashLang()
  );
  const [selectedScreenEffect, _setSelectedScreenEffect] = useState(
    getSelectedScreenEffect()
  );
  // const [selectedLockedVideo, _setSelectedLockedVideo] = useState(
  //   getSelectedLockedVideo()
  // );

  return (
    <>
      <Header>Account</Header>
      <h2>Account Info</h2>
      <ul>
        <li>
          <strong>Email:</strong> {user.email}
        </li>

        <li>
          <label htmlFor="lang">
            <strong> Current Language</strong>
          </label>
          <br />
          <select
            name="lang"
            id="lang"
            value={selectedLang}
            style={{ width: "min-content", marginTop: "10px" }}
            onChange={(e) => {
              setSelectedLang(e.target.value);
              _setSelectedLang(e.target.value);
            }}
          >
            <option value="all">All Languages</option>
            <option value="nl">Dutch</option>
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            <option value="it">Italian</option>
            <option value="pt">Portguese</option>
            <option value="es">Spanish</option>
            <option value="sv">Swedish</option>
          </select>
        </li>
        <li>
          <label htmlFor="lang">
            <strong> Flashcard Definition Language</strong>
          </label>
          <br />
          <select
            name="flash-lang"
            id="flash-lang"
            value={selectedFlashLang}
            style={{ width: "min-content", marginTop: "10px" }}
            onChange={(e) => {
              setSelectedFlashLang(e.target.value);
              _setSelectedFlashLang(e.target.value);
            }}
          >
            <option value="all">All Languages</option>
            <option value="nl">Dutch</option>
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            <option value="it">Italian</option>
            <option value="pt">Portguese</option>
            <option value="es">Spanish</option>
            <option value="sv">Swedish</option>
          </select>
        </li>
      </ul>
      <ul>
        <li>
          <label htmlFor="screen-effect">
            <strong> Current Screen Effect</strong>
          </label>
          <br />
          <select
            name="screen-effect"
            id="screen-effect"
            value={selectedScreenEffect}
            style={{ width: "min-content", marginTop: "10px" }}
            onChange={(e) => {
              setSelectedScreenEffect(e.target.value);
              _setSelectedScreenEffect(e.target.value);
            }}
          >
            <option value="opacity">Blur and Grayscale</option>
            <option value="none">Clear</option>
          </select>
        </li>
        {/* <li>
          <label htmlFor="lock-video">
            <strong>Unlock/Lock Video Player</strong>
          </label>
          <br />
          <select
            name="lock-video"
            id="lock-video"
            value={selectedLockedVideo}
            style={{ width: "min-content", marginTop: "10px" }}
            onChange={(e) => {
              setSelectedLockedVideo(e.target.value);
              _setSelectedLockedVideo(e.target.value);
            }}
          >
            <option value="locked">Locked</option>
            <option value="unlocked">Unlocked</option>
          </select>
        </li> */}
      </ul>

      <h2>Account Actions</h2>
      <ul>
        <li>
          <button
            style={{ width: "fit-content" }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/account/reset-password?email=${user.email}`);
            }}
          >
            Reset Password
          </button>
        </li>
        <li>
          <button
            style={{ width: "fit-content" }}
            onClick={(e) => {
              e.preventDefault();
              window.localStorage.removeItem("token");
              user.setUser(null);

              window.open(window.location.origin, "_self");
            }}
          >
            Logout
          </button>
        </li>
      </ul>
    </>
  );
}

export default Account;
